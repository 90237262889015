.new-grid-for-360 {
  margin-top: 30px;
  display: flex;
  gap: 15px;
}

.footer-brands {
  display: flex;
  gap: 15px;
  padding: 15px;
  text-align: initial;
  align-items: center;
}

.footer-brands a {
  border-radius: 0;
  margin-left: 0;
  display: block;
  font-size: 0;
}

.footer-container {
  display: flex;
  /* padding: 20px 5px; */
  justify-content: space-between;
}

@media (max-width: 768px) {
  .footer-container .grid-cell {
    display: flex !important;
    justify-content: center !important;
  }
}

@media (max-width: 600px) {
  .footer-brands {
    flex-direction: initial;
  }
  .footer-container {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
  }
}

footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  z-index: 99;
}


